import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  sitemapPageFilter,
  sitemapBlogFilter,
  sitemapFaqFilter,
  sitemapDocumentCategoryFilter,
  sitemapHomePageFilter,
} from '@web-creditea/utils';
import {
  SitemapHomePagesDocument,
  SitemapHomePagesQuery,
  SitemapHomePagesQueryVariables,
  SitemapPagesDocument,
  SitemapPagesQuery,
  SitemapPagesQueryVariables,
} from '@web-creditea/interfaces';

export const sitemapGetAllPages =
  (client: ApolloClient<NormalizedCacheObject>) => async (market: string) => {
    const { data } = await client.query<SitemapPagesQuery, SitemapPagesQueryVariables>({
      query: SitemapPagesDocument,
      variables: { market },
      fetchPolicy: 'no-cache',
    });

    const {
      allPages,
      allBlogPage,
      allBlog,
      allBlogCategories,
      allFaqPage,
      allFaq,
      allFaqCategories,
    } = data;
    return [
      ...allPages.filter(sitemapPageFilter),
      ...allBlogPage.filter(sitemapPageFilter),
      ...allBlog.filter(sitemapBlogFilter),
      ...allBlogCategories.filter(sitemapDocumentCategoryFilter),
      ...allFaqPage.filter(sitemapPageFilter),
      ...allFaq.filter(sitemapFaqFilter),
      ...allFaqCategories.filter(sitemapDocumentCategoryFilter),
    ];
  };

export const sitemapGetAllHomePages =
  (client: ApolloClient<NormalizedCacheObject>) => async (market: string) => {
    const { data } = await client.query<SitemapHomePagesQuery, SitemapHomePagesQueryVariables>({
      query: SitemapHomePagesDocument,
      variables: { market },
      fetchPolicy: 'no-cache',
    });
    const publishedPages = data.allHomePage.filter(sitemapHomePageFilter);

    return publishedPages;
  };

export type AllPagesSitemap = Awaited<ReturnType<ReturnType<typeof sitemapGetAllPages>>>;
export type HomePagesSitemap = Awaited<ReturnType<ReturnType<typeof sitemapGetAllHomePages>>>;
