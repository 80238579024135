import {
  ResourceSitemap,
  SitemapHomePagesQuery,
  SitemapPagesQuery,
} from '@web-creditea/interfaces';
import type { AllPagesSitemap, HomePagesSitemap } from '../dataFetchers';
import isEmpty from 'lodash/isEmpty';

type PrimaryCountryCode = 'CZ' | 'GL' | 'EE' | 'LT' | 'LV' | 'MX';
type PrimaryLanguageCode = 'cz' | 'et' | 'en' | 'lt' | 'lv' | 'es';
type SecondaryLanguageCode = 'ru' | 'es' | undefined;

interface SitemapArgs {
  pages: AllPagesSitemap;
  homePages: HomePagesSitemap;
  appUrl: string;
  primaryCountryCode: PrimaryCountryCode;
  primaryLanguageCode: PrimaryLanguageCode;
  secondaryLanguageCode: SecondaryLanguageCode;
  faqTranslation: string;
}

type HomePagesSitemapArgs = Omit<SitemapArgs, 'pages' | 'faqTranslation'>;
type PagesSitemapArgs = Omit<SitemapArgs, 'homePages'>;

const basePathsMap: Record<PrimaryCountryCode, string> = {
  CZ: '',
  GL: '',
  MX: '',
  EE: '/ee',
  LT: '/lt',
  LV: '/lv',
};

type PagesQuery = Pick<
  SitemapPagesQuery,
  | 'allPages'
  | 'allBlogPage'
  | 'allBlog'
  | 'allFaqPage'
  | 'allFaq'
  | 'allFaqCategories'
  | 'allBlogCategories'
>;

type SitemapPagesQueryUnion<T extends PagesQuery> = {
  [K in keyof T]: T[K];
}[keyof PagesQuery][number];

type SitemapDocument =
  | SitemapHomePagesQuery['allHomePage'][number]
  | SitemapPagesQueryUnion<PagesQuery>;

export const createSitemapFilter =
  (filters: Array<(resource: SitemapDocument) => boolean>) => (resource: SitemapDocument) =>
    filters.every((filter) => filter(resource));

const isPublished = (resource: SitemapDocument) => !resource?._id?.startsWith('drafts');

const hasSlug = (resource: SitemapDocument) => 'slug' in resource && !isEmpty(resource.slug);

const hasCategorySlug = (resource: SitemapDocument) =>
  'category' in resource &&
  resource.category != undefined &&
  'slug' in resource.category &&
  !isEmpty(resource.category.slug);

const hasBlogCategorySlug = (resource: SitemapDocument) =>
  'category' in resource &&
  resource.category != undefined &&
  'category' in resource.category &&
  resource.category.category != undefined &&
  'slug' in resource.category.category &&
  !isEmpty(resource.category.category.slug);

const hasFaqCategorySlug = (resource: SitemapDocument) =>
  'faqCategory' in resource &&
  resource.faqCategory != undefined &&
  'category' in resource.faqCategory &&
  resource.faqCategory.category != undefined &&
  'slug' in resource.faqCategory.category &&
  !isEmpty(resource.faqCategory.category.slug);

const isIndexed = (resource: SitemapDocument) =>
  !('seoGroup' in resource) || !(resource.seoGroup?.noFollow && resource.seoGroup?.noIndex);

export const sitemapHomePageFilter = createSitemapFilter([isPublished, isIndexed]);
export const sitemapPageFilter = createSitemapFilter([isPublished, hasSlug, isIndexed]);
export const sitemapBlogFilter = createSitemapFilter([isPublished, hasBlogCategorySlug, isIndexed]);
export const sitemapFaqFilter = createSitemapFilter([isPublished, hasFaqCategorySlug, isIndexed]);
export const sitemapDocumentCategoryFilter = createSitemapFilter([
  isPublished,
  hasCategorySlug,
  isIndexed,
]);

const generateURL = (appUrl: string, basePath: string) => (url?: string) => {
  return new URL(basePath + (url ?? ''), appUrl);
};

export const generateSiteMapDataForHomePages = (args: HomePagesSitemapArgs): ResourceSitemap[] => {
  const basePath = basePathsMap[args.primaryCountryCode];
  const generateFullURL = generateURL(args.appUrl, basePath);

  if (args.secondaryLanguageCode) {
    return args.homePages.map((homePage) => {
      return {
        lastMod: homePage._updatedAt as string,
        loc:
          homePage.language?.toLowerCase() === args.primaryLanguageCode
            ? new URL(`${basePath}`, args.appUrl)
            : new URL(`${basePath}/${args.secondaryLanguageCode}`, args.appUrl),
      };
    });
  } else {
    return args.homePages.map((homePage) => {
      return {
        lastMod: homePage._updatedAt as string,
        loc: generateFullURL(),
      };
    });
  }
};

const generateSitemapDataForPages = (args: PagesSitemapArgs): ResourceSitemap[] => {
  const resourceSitemap: ResourceSitemap[] = args.pages
    .map((page) => {
      const countryCode = basePathsMap[args.primaryCountryCode];
      let basePath = countryCode;
      if (args.secondaryLanguageCode) {
        basePath =
          page.language?.toLowerCase() === args.primaryLanguageCode
            ? countryCode
            : `${countryCode}/${args.secondaryLanguageCode}`;
      }

      const generateFullURL = generateURL(args.appUrl, basePath);

      switch (page._type) {
        case 'pages': {
          if (!('slug' in page)) return {};
          return {
            lastMod: page._updatedAt as string,
            loc: generateFullURL(`/${page.slug?.current}`),
          };
        }
        case 'blogPage': {
          return {
            lastMod: page._updatedAt as string,
            loc: generateFullURL(`/blog`),
          };
        }
        case 'blogCategories': {
          if (!('category' in page && page.category != undefined && 'slug' in page.category))
            return {};
          return {
            lastMod: page._updatedAt as string,
            loc: generateFullURL(`/blog/${page.category?.slug?.current}`),
          };
        }
        case 'blog': {
          if (!('slug' in page)) return {};
          return {
            lastMod: page._updatedAt as string,
            loc: generateFullURL(`/blog/post/${page.slug?.current}`),
          };
        }
        case 'faqPage': {
          return {
            lastMod: page._updatedAt as string,
            loc: generateFullURL(`/${args.faqTranslation}`),
          };
        }
        case 'faqCategories': {
          if (!('category' in page && page.category != undefined && 'slug' in page.category))
            return {};
          return {
            lastMod: page._updatedAt as string,
            loc: generateFullURL(`/${args.faqTranslation}/${page.category?.slug?.current}`),
          };
        }
        case 'faq': {
          if (
            !(
              'faqCategory' in page &&
              page.faqCategory != undefined &&
              page.faqCategory.category != undefined
            )
          )
            return {};
          return {
            lastMod: page._updatedAt as string,
            loc: generateFullURL(
              `/${args.faqTranslation}/${page.faqCategory?.category?.slug?.current}/${page.slug?.current}`
            ),
          };
        }
        default: {
          return {} as ResourceSitemap;
        }
      }
    })
    .filter((resource): resource is ResourceSitemap => !isEmpty(resource));

  return resourceSitemap;
};

export const handleSitemapData = (args: SitemapArgs): ResourceSitemap[] => {
  const homePagesSitemapData = generateSiteMapDataForHomePages(args);
  const pagesSitemapData = generateSitemapDataForPages(args);

  return [...homePagesSitemapData, ...pagesSitemapData];
};

export const generateSitemap = (sitemapData: ResourceSitemap[]) => {
  const urls = sitemapData
    .map(({ loc, lastMod }) => {
      return `
        <url>
          <loc>${loc.href}</loc>
          <lastmod>${lastMod}</lastmod>
        </url>`;
    })
    .join('');

  return `<?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xhtml="http://www.w3.org/1999/xhtml">
        ${urls}
      </urlset>`;
};
