var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0kFRp3ttRouwgGcXO4hZq"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/ee";

import * as Sentry from '@sentry/nextjs';
import { MARKET } from './src/constants';

Sentry.setTag('market', MARKET);
Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: false,
  environment: process.env['CONFIG_PROFILE'] || '',

  // Replay may only be enabled for the client-side
  integrations: [new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Filtering errors
  beforeSend(errorEvent, eventHint: Sentry.EventHint) {
    // Filter all gtm.js errors
    if (eventHint.originalException instanceof Error && eventHint.originalException.stack) {
      if (eventHint.originalException.stack.match(/gtm.js/i)) {
        return null;
      }
    }
    return eventHint;
  },

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
