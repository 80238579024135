import React, { useMemo } from 'react';
import {
  BaseCarousel,
  IconNames,
  Typography,
  CarouselCard,
  CarouselPromotionCard,
  LinkFragment,
} from '@ipf-ui/components-library';
import Image from 'next/image';
import * as Styled from './CarouselSection.style';
import type { TeamCarouselCard, TestimonialCard, PromotionCard } from '@web-creditea/interfaces';
import { definedValues, nonNullKeys, returnMaybe } from '../../../helpers';
import Link from 'next/link';

interface CarouselProps {
  title?: string;
  cards?: (TeamCarouselCard | TestimonialCard | PromotionCard)[];
}

const CarouselSection = ({ title, cards }: CarouselProps) => {
  const cardsArray = useMemo(
    () =>
      cards?.map((card) => {
        return (
          <React.Fragment key={card._key}>
            {card.__typename === 'TestimonialCard' && (
              <CarouselCard
                type={card.__typename}
                description={returnMaybe(card.description)}
                iconName={card.iconName as IconNames}
                iconBackgroundColor={returnMaybe(card.iconColor?.value)}
                name={returnMaybe(card.name)}
                position={returnMaybe(card.position)}
                year={returnMaybe(card.year)}
              />
            )}
            {card.__typename === 'TeamCarouselCard' && (
              <CarouselCard
                type={card.__typename}
                image={
                  card?.image?.asset?.url && (
                    <Image
                      src={card.image.asset.url}
                      alt={card.image.customAltText ?? card.image.asset.altText ?? ''}
                      fill
                    />
                  )
                }
                socialMedia={definedValues(card.socialMedia).map(
                  (social) => nonNullKeys({ ...social, icon: social.icon as IconNames })!
                )}
                description={returnMaybe(card.description)}
                name={returnMaybe(card.name)}
                position={returnMaybe(card.position)}
                country={returnMaybe(card.country)}
              />
            )}
            {card.__typename === 'PromotionCard' && (
              <CarouselPromotionCard
                type={card.__typename}
                backgroundImage={
                  card?.backgroundImage?.asset?.url && (
                    <Image
                      src={card.backgroundImage.asset.url}
                      alt={
                        card.backgroundImage.customAltText ??
                        card.backgroundImage.asset.altText ??
                        ''
                      }
                      fill
                    />
                  )
                }
                logoImage={
                  card?.logoImage?.asset?.url && (
                    <Image
                      src={card.logoImage.asset.url}
                      alt={card.logoImage.customAltText ?? card.logoImage.asset.altText ?? ''}
                      fill
                    />
                  )
                }
                textColor={returnMaybe(card.textColor?.value)}
                firstLineText={returnMaybe(card.firstLineText)}
                secondLineText={returnMaybe(card.secondLineText)}
                uppercaseText={returnMaybe(card.uppercaseText)}
                smallPrint={returnMaybe(card.smallPrint)}
                link={returnMaybe(card.link) as LinkFragment}
                NextLink={Link}
              />
            )}
          </React.Fragment>
        );
      }),
    [cards]
  );
  return (
    <Styled.CarouselWrapper>
      {title && (
        <>
          <Typography.Header2>{title}</Typography.Header2>
          <Styled.ResponsiveGutter />
        </>
      )}
      <BaseCarousel cards={cardsArray} />
    </Styled.CarouselWrapper>
  );
};

export default CarouselSection;
